<template>
  <div>
    <modal
      :name="`modal-portal-photos`"
      :width="`80%`"
      :height="`auto`"
      scrollable
    >
      <v-form ref="form" lazy-validation>
        <v-card class="text--primary">
          <v-card-text class="d-flex justify-content-between align-item-start">
            <v-row>
              <v-col cols="12" sm="12" md="8">
                <span class="text-h6 text--primary">{{ modalTitle }}</span>
              </v-col>
              <v-col cols="12" sm="12" md="4" v-if="modalPosDetail">
                <h6>{{ modalPosDetail }}</h6>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text class="text--primary">
            <b-alert v-if="isLoading" show variant="info">
              <b-icon
                icon="arrow-counterclockwise"
                animation="spin-reverse"
              ></b-icon>
              cargando datos...
            </b-alert>
            <template v-else-if="foundPortals.length > 0">
              <v-checkbox
                v-model="isCompactView"
                label="Compación Foto"
                hide-details="auto"
              ></v-checkbox>
              <div
                v-if="isCompactView"
                class="d-flex align-items-center justify-content-center"
              >
                <v-row class="mt-2">
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="filteredComparePhoto"
                      v-bind:label="`${$t('Photo')} ${$t('brands.name')}`"
                      v-bind:placeholder="$t('start_typing_to_search')"
                      item-text="title"
                      item-value="id"
                      :search-input.sync="search_photo_names"
                      :loading="isPhotoNamesSearching"
                      :items="filtered_photo_names"
                      hide-no-data
                      hide-selected
                      filled
                      @change="onChangeSelectedPhotoName"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </div>
              <h5 class="text-center">{{ portalItem.photo_name }}</h5>
              <div
                class="d-flex justify-content-center"
                v-if="availablePosList.length > 1"
              >
                <b-icon
                  class="my-1 btn-portal-move-pos"
                  variant="primary"
                  icon="caret-up-fill"
                  font-scale="3"
                  @click="onClickMovePos('prev')"
                  v-if="this.indexOfCurrentPos > 0"
                ></b-icon>
              </div>
              <v-row class="portal-card-slider">
                <b-icon
                  class="my-1 btn-portal-card-slider portal-card-slider-prev"
                  variant="primary"
                  icon="caret-left-fill"
                  font-scale="3"
                  @click="slide('left')"
                  v-if="
                    foundPortals.length > itemsPerPage && visibleItems !== 0
                  "
                ></b-icon>
                <template v-for="(portalItem, key) in foundPortals">
                  <v-col
                    cols="12"
                    md="6"
                    class="d-flex justify-content-center my-4"
                    :key="`portal_item_${key}`"
                    v-if="
                      key >= visibleItems && key < visibleItems + itemsPerPage
                    "
                  >
                    <v-card elevation="2" class="portal-card-slider-item">
                      <v-card-text>
                        <h6 class="text-center">
                          {{
                            moment(portalItem?.worksessionDate).format(
                              "DD/MM/YYYY"
                            )
                          }}
                        </h6>
                        <v-row>
                          <v-col cols="12" md="12" style="min-height: 250px">
                            <ImageSlider
                              :attachments="getAttachments(portalItem)"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="12">
                            <div class="mt-4">
                              <h5 class="text--primary">
                                {{ getPunctuationLabel(portalItem) }}
                              </h5>
                            </div>
                            <v-row>
                              <v-col
                                cols="12"
                                sm="12"
                                class="text--primary"
                                v-if="
                                  commentsPhotosPortal(portalItem).length > 0
                                "
                              >
                                <v-col
                                  cols="12"
                                  md="12"
                                  :key="`photo_comment_${index}`"
                                  v-for="(commentItem,
                                  index) in commentsPhotosPortal(portalItem)"
                                >
                                  <v-col cols="12" md="12" lg="12">
                                    <h6 class="my-2">
                                      {{ commentItem.label }}
                                    </h6>
                                    <v-rating
                                      dense
                                      length="5"
                                      size="30"
                                      v-model="commentItem.rating"
                                      :readonly="!commentItem.isAccess"
                                      :required="commentItem.isAccess"
                                      :rules="
                                        commentItem.isAccess
                                          ? requiredRules
                                          : []
                                      "
                                    ></v-rating>
                                  </v-col>
                                  <v-col cols="12" md="12" lg="12">
                                    <v-textarea
                                      :readonly="!commentItem.isAccess"
                                      v-bind:class="
                                        'gpv_comment_text ' +
                                          (!commentItem.isAccess
                                            ? 'readonly'
                                            : '')
                                      "
                                      :required="commentItem.isAccess"
                                      :rules="
                                        commentItem.isAccess
                                          ? requiredCommentsRules
                                          : []
                                      "
                                      v-model="commentItem.text"
                                    ></v-textarea>
                                  </v-col>
                                  <v-btn
                                    color="blue darken-1"
                                    @click="
                                      onSaveClick(commentItem, portalItem)
                                    "
                                    v-if="commentItem.isAccess"
                                    :loading="isCommentLoading"
                                    >{{ $t("save") }}</v-btn
                                  >
                                </v-col>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                            v-if="
                              portalItem?.surveyQuestionsForThePortalPhoto &&
                                portalItem?.surveyQuestionsForThePortalPhoto
                                  .length > 0
                            "
                          >
                            <table
                              class="table b-table table-striped table-bordered table-sm b-table-stacked-sm routepage_ps_table mb-0 text-sm table-dense"
                            >
                              <tr>
                                <th class="stats-th-title">
                                  {{ $t("Question") }}
                                </th>
                                <th class="stats-th-title">
                                  {{ $t("value") }}
                                </th>
                              </tr>
                              <tr
                                v-for="(questionItem,
                                key) in portalItem.surveyQuestionsForThePortalPhoto"
                                :key="
                                  `portal_photos_questions_${portalItem.id}_${questionItem.id}_${key}`
                                "
                              >
                                <td>{{ questionItem.name }}</td>
                                <td>{{ questionItem.valueLabel }}</td>
                              </tr>
                            </table>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </template>
                <b-icon
                  class="my-1 btn-portal-card-slider portal-card-slider-next"
                  variant="primary"
                  icon="caret-right-fill"
                  font-scale="3"
                  @click="slide('right')"
                  v-if="
                    foundPortals.length > itemsPerPage &&
                      visibleItems + itemsPerPage < foundPortals.length
                  "
                ></b-icon>
              </v-row>
              <div
                class="d-flex justify-content-center"
                v-if="availablePosList.length > 1"
              >
                <b-icon
                  class="my-1 btn-portal-move-pos"
                  variant="primary"
                  icon="caret-down-fill"
                  font-scale="3"
                  @click="onClickMovePos('next')"
                  v-if="
                    this.indexOfCurrentPos < this.availablePosList.length - 1
                  "
                ></b-icon>
              </div>
              <v-row
                class="portal-card-slider mt-4"
                v-if="isCompactView && compactPhotos.length > 0"
              >
                <v-col cols="12" md="12">
                  <h5 class="text-center">{{ selectedComparePhotoName }}</h5>
                </v-col>
                <b-icon
                  class="my-1 btn-portal-card-slider portal-card-slider-prev"
                  variant="primary"
                  icon="caret-left-fill"
                  font-scale="3"
                  @click="compactSlide('left')"
                  v-if="
                    compactPhotos.length > itemsPerPage &&
                      compactVisibleItems !== 0
                  "
                ></b-icon>
                <template v-for="(portalItem, key) in compactPhotos">
                  <v-col
                    cols="12"
                    md="6"
                    class="d-flex justify-content-center my-4"
                    :key="`portal_item_${key}`"
                    v-if="
                      key >= compactVisibleItems &&
                        key < compactVisibleItems + itemsPerPage
                    "
                  >
                    <v-card elevation="2" class="portal-card-slider-item">
                      <v-card-text>
                        <h6 class="text-center">
                          {{
                            moment(portalItem?.worksessionDate).format(
                              "DD/MM/YYYY"
                            )
                          }}
                        </h6>
                        <v-row>
                          <v-col cols="12" md="12" style="min-height: 250px">
                            <ImageSlider
                              :attachments="getAttachments(portalItem)"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="6">
                            <div class="mt-4">
                              <h5 class="text--primary">
                                {{ getPunctuationLabel(portalItem) }}
                              </h5>
                            </div>
                            <v-row>
                              <v-col
                                cols="12"
                                sm="12"
                                class="text--primary"
                                v-if="
                                  commentsPhotosPortal(portalItem).length > 0
                                "
                              >
                                <v-col
                                  cols="12"
                                  md="12"
                                  :key="`photo_comment_${index}`"
                                  v-for="(commentItem,
                                  index) in commentsPhotosPortal(portalItem)"
                                >
                                  <v-col cols="12" md="12" lg="12">
                                    <h6 class="my-2">
                                      {{ commentItem.label }}
                                    </h6>
                                    <v-rating
                                      dense
                                      length="5"
                                      size="30"
                                      v-model="commentItem.rating"
                                      :readonly="!commentItem.isAccess"
                                      :required="commentItem.isAccess"
                                      :rules="
                                        commentItem.isAccess
                                          ? requiredRules
                                          : []
                                      "
                                    ></v-rating>
                                  </v-col>
                                  <v-col cols="12" md="12" lg="12">
                                    <v-textarea
                                      :readonly="!commentItem.isAccess"
                                      v-bind:class="
                                        'gpv_comment_text ' +
                                          (!commentItem.isAccess
                                            ? 'readonly'
                                            : '')
                                      "
                                      :required="commentItem.isAccess"
                                      :rules="
                                        commentItem.isAccess
                                          ? requiredCommentsRules
                                          : []
                                      "
                                      v-model="commentItem.text"
                                    ></v-textarea>
                                  </v-col>
                                  <v-btn
                                    color="blue darken-1"
                                    @click="
                                      onSaveClick(commentItem, portalItem)
                                    "
                                    v-if="commentItem.isAccess"
                                    :loading="isCommentLoading"
                                    >{{ $t("save") }}</v-btn
                                  >
                                </v-col>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                            v-if="
                              portalItem?.surveyQuestionsForThePortalPhoto &&
                                portalItem?.surveyQuestionsForThePortalPhoto
                                  .length > 0
                            "
                          >
                            <table
                              class="table b-table table-striped table-bordered table-sm b-table-stacked-sm routepage_ps_table mb-0 text-sm table-dense"
                            >
                              <tr>
                                <th class="stats-th-title">
                                  {{ $t("Question") }}
                                </th>
                                <th class="stats-th-title">
                                  {{ $t("value") }}
                                </th>
                              </tr>
                              <tr
                                v-for="(questionItem,
                                key) in portalItem.surveyQuestionsForThePortalPhoto"
                                :key="
                                  `portal_photos_questions_${portalItem.id}_${questionItem.id}_${key}`
                                "
                              >
                                <td>{{ questionItem.name }}</td>
                                <td>{{ questionItem.valueLabel }}</td>
                              </tr>
                            </table>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </template>
                <b-icon
                  class="my-1 btn-portal-card-slider portal-card-slider-next"
                  variant="primary"
                  icon="caret-right-fill"
                  font-scale="3"
                  @click="compactSlide('right')"
                  v-if="
                    compactPhotos.length > itemsPerPage &&
                      compactVisibleItems + itemsPerPage < compactPhotos.length
                  "
                ></b-icon>
              </v-row>
              <b-alert
                v-if="isCompactView && !compactPhotos.length"
                show
                variant="warning"
              >
                <i> No hay datos....</i>
              </b-alert>
            </template>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" @click="downloadAllImages">
              <b-icon icon="download" aria-hidden="true"></b-icon>
              {{ $t("Download") }}
            </v-btn>
            <v-btn color="blue darken-1" text @click.stop="hideModal()">
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </modal>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import ImageSlider from "./ImageSlider.vue";
import download from "js-file-download";
import moment from "moment";
import { logInfo, logError } from "@/utils";

export default {
  name: "DialogPortalPhotos",
  props: ["portalItem", "assignTypes"],
  data() {
    return {
      filteredComparePhoto: null,
      search_photo_names: null,
      isPhotoNamesSearching: false,
      filtered_photo_names: [],
      compactPhotos: [],
      compactVisibleItems: 0, // New property to track visible items
      isCompactView: false,
      photo_detail: null,
      foundPortals: [],
      availablePosList: [],
      currentSelectedPosId: null,
      visibleItems: 0, // New property to track visible items
      initItemsPerPage: 2, // Number of items to show per page
      isLoading: false,
      isCommentLoading: false,

      requiredRules: [v => !!v || "Required"],
      requiredRulesArray: [v => (!!v && v.length > 0) || "Required"],
      requiredCommentsRules: [v => !!v || "Escribe los comentarios"]
    };
  },
  components: { ImageSlider },
  watch: {
    async search_photo_names(val) {
      this.search_photo_names_list(val);
    }
  },
  computed: {
    selectedComparePhotoName() {
      let ret = null;
      if (this.isCompactView && this.filteredComparePhoto) {
        let found = this.filtered_photo_names.find(
          el => el.id == this.filteredComparePhoto
        );
        if (found) {
          ret = found?.title;
        }
      }
      return ret;
    },
    itemsPerPage() {
      return this.initItemsPerPage;
    },
    modalTitle() {
      let titles = [];
      if (this.photo_detail) {
        const {
          survey_name,
          survey_component_name,
          survey_question_name,
          assignType,
          worksession_survey_response
        } = this.photo_detail;
        if (survey_name) titles.push(survey_name);
        if (survey_component_name) titles.push(survey_component_name);
        if (assignType) titles.push(this.assignTypes[assignType]);
        if (worksession_survey_response) {
          if (
            assignType === "PRODUCT" &&
            worksession_survey_response?.product
          ) {
            titles.push(worksession_survey_response?.product?.name);
          } else if (
            assignType === "MATERIAL" &&
            worksession_survey_response?.material
          ) {
            titles.push(worksession_survey_response?.material?.name);
          } else if (worksession_survey_response?.survey_component_option) {
            titles.push(
              worksession_survey_response?.survey_component_option?.optionLabel
            );
          }
        }
        if (survey_question_name) titles.push(survey_question_name);
      }
      if (titles.length > 0) {
        return titles.join(" / ");
      }
      return "Photo Detail";
    },
    modalPosDetail() {
      let ret = [];
      if (this.currentPos) {
        const { id, chain, name, address_label } = this.currentPos;
        if (id) ret.push(id);
        if (this.idPosBrandOfCurrentPos) ret.push(this.idPosBrandOfCurrentPos);
        if (chain) ret.push(chain?.name);
        if (name) ret.push(name);
        if (address_label) ret.push(address_label);
      }
      if (ret.length > 0) return ret.join(" , ");
      return null;
    },
    idPosBrandOfCurrentPos() {
      let ret = [];
      if (
        this.currentPos &&
        this.currentPos?.brands &&
        this.currentPos?.brands.length > 0
      ) {
        let posbrands = this.currentPos?.brands.map(el => el.posbrands);
        if (posbrands.length > 0) {
          ret = posbrands.map(el => el.idPosBrand);
        }
      }
      if (ret.length > 0) return ret.join(",");
      return null;
    },
    currentPos() {
      let foundPos = null;
      if (this.availablePosList.length > 0 && this.currentSelectedPosId) {
        foundPos = this.availablePosList.find(
          el => el.id == this.currentSelectedPosId
        );
      }
      return foundPos;
    },
    indexOfCurrentPos() {
      const indexOfCurrentPos = this.availablePosList
        .map(el => el.id)
        .indexOf(this.currentSelectedPosId);
      return indexOfCurrentPos;
    }
  },
  methods: {
    moment,
    async onSaveClick(commentItem, portalItem) {
      const id = portalItem.worksession_survey_photos_portal.id;
      if (commentItem.text && commentItem.text !== "") {
        const body = {
          label: commentItem.label,
          newComment: commentItem.text,
          newRating: commentItem.rating
        };
        // this.isLoading = true;
        this.isCommentLoading = true;
        const data = await ApiService.post(
          `salesforce/portalphotos/updateComment/${id}`,
          body
        );
        this.isCommentLoading = false;
        if (data.success) {
          logInfo(`Actualizar el comentario del ${commentItem.label}`);
          // this.showModal(this.portalItem);
        } else {
          logError(data.message);
          // this.showModal(this.portalItem);
        }
      }
    },
    async search_photo_names_list(val) {
      let fetch_url = `salesforce/portalphotos/getfilterlist?column=portal_photo_name&isFullText=true&filterValue=${val}`;
      const resp = await ApiService.get(fetch_url);
      this.filtered_photo_names = resp.data;
    },
    async onChangeSelectedPhotoName() {
      let splitArr = this.filteredComparePhoto.split("_");
      splitArr = splitArr.map(el => (el == "null" ? null : el));
      const [
        surveyQuestionId,
        productId,
        materialId,
        componentOptionId
      ] = splitArr;
      let form_data = {
        posId: this.currentPos.id,
        surveyQuestionId,
        productId,
        materialId,
        componentOptionId
      };
      let fetch_url = `salesforce/portalphotos/getcompactphotos`;
      const resp = await ApiService.post(fetch_url, form_data);
      if (resp && resp?.compactPhotos) {
        this.compactPhotos = resp?.compactPhotos;
        this.compactVisibleItems =
          this.compactPhotos.length - this.itemsPerPage;
        if (this.compactVisibleItems < 0) this.compactVisibleItems = 0;
      }
    },
    onClickMovePos(direction) {
      const {
        assignType,
        productId,
        materialId,
        componentOptionId,
        surveyQuestionId
      } = this.portalItem;
      let worksessionSurveyResponseId = this.portalItem.id;
      let movePosId = null;
      if (direction === "prev" && this.indexOfCurrentPos > 0) {
        movePosId = this.availablePosList[this.indexOfCurrentPos - 1].id;
      } else if (
        direction === "next" &&
        this.indexOfCurrentPos < this.availablePosList.length - 1
      ) {
        movePosId = this.availablePosList[this.indexOfCurrentPos + 1].id;
      }
      if (surveyQuestionId && movePosId) {
        this.init(
          worksessionSurveyResponseId,
          assignType,
          productId,
          materialId,
          componentOptionId,
          surveyQuestionId,
          movePosId
        );
      }
    },
    compactSlide(direction) {
      const totalItems = this.compactPhotos.length;
      const maxVisibleItems = totalItems - this.itemsPerPage;

      if (direction === "left" && this.compactVisibleItems > 0) {
        this.compactVisibleItems -= this.itemsPerPage;
      } else if (
        direction === "right" &&
        this.compactVisibleItems < maxVisibleItems
      ) {
        this.compactVisibleItems += this.itemsPerPage;
      }
      if (this.compactVisibleItems < 0) this.compactVisibleItems = 0;
    },
    slide(direction) {
      const totalItems = this.foundPortals.length;
      const maxVisibleItems = totalItems - this.itemsPerPage;

      if (direction === "left" && this.visibleItems > 0) {
        this.visibleItems -= this.itemsPerPage;
      } else if (direction === "right" && this.visibleItems < maxVisibleItems) {
        this.visibleItems += this.itemsPerPage;
      }
      if (this.visibleItems < 0) this.visibleItems = 0;
    },
    showModal(portalItem) {
      const { surveyQuestionId, posId } = portalItem;
      let worksessionSurveyResponseId = portalItem.id;
      if (surveyQuestionId && posId) {
        const {
          productId,
          materialId,
          componentOptionId,
          survey_name,
          survey_component_name,
          survey_question_name,
          assignType,
          worksession_survey_response
        } = portalItem;
        if (worksession_survey_response) {
          this.photo_detail = {
            productId,
            materialId,
            componentOptionId,
            survey_name,
            survey_component_name,
            survey_question_name,
            assignType,
            worksession_survey_response
          };
        }
        this.$modal.show(`modal-portal-photos`);
        this.init(
          worksessionSurveyResponseId,
          assignType,
          productId,
          materialId,
          componentOptionId,
          surveyQuestionId,
          posId
        );
      }
    },
    hideModal() {
      this.$modal.hide(`modal-portal-photos`);
    },
    getPunctuationLabel(portalItem) {
      let str = ``;
      let punctuationValue = this.getPunctuationValue(
        portalItem.worksession_survey_photos_portal
      );
      if (punctuationValue !== null) {
        return `Punctuation: ${punctuationValue} / 5`;
      }
      return str;
    },
    getPunctuationValue(portalItem) {
      let ret = null;
      if (portalItem) {
        const {
          scoreGPV,
          scoreGPVBy,
          ppScoreGPVBy,
          scoreBrand,
          scoreBrandBy,
          ppScoreBrandBy,
          scoreAdmin,
          scoreAdminBy,
          ppScoreAdminBy
        } = portalItem;
        if (scoreBrand !== null && !isNaN(scoreBrand)) {
          return scoreBrand;
        } else if (scoreAdmin !== null && !isNaN(scoreAdmin)) {
          return scoreAdmin;
        } else if (scoreGPV !== null && !isNaN(scoreGPV)) {
          return scoreGPV;
        }
      }
      return ret;
    },
    checkAndChangeScore(score) {
      if (score !== null && !isNaN(score)) return score;
      return 0;
    },
    commentsPhotosPortal(portalItem) {
      const { isAdminEditing, isBrandEditing, isGPVEditing } = this.portalItem;
      let ret = [];
      if (portalItem && portalItem?.worksession_survey_photos_portal) {
        const {
          commentsAdmin,
          scoreAdminBy,
          isCommentsAdmin,
          ppScoreAdminBy,
          commentsBrand,
          scoreBrandBy,
          isCommentsBrand,
          ppScoreBrandBy,
          commentsGPV,
          scoreGPVBy,
          isCommentsGPV,
          ppScoreGPVBy,
          scoreGPV,
          scoreAdmin,
          scoreBrand
        } = portalItem.worksession_survey_photos_portal;
        ret.push({
          label: "Admin",
          text: commentsAdmin !== null ? commentsAdmin : "",
          rating: this.checkAndChangeScore(scoreAdmin),
          isAccess: isAdminEditing
        });
        ret.push({
          label: "Marca",
          text: commentsBrand !== null ? commentsBrand : "",
          rating: this.checkAndChangeScore(scoreBrand),
          isAccess: isBrandEditing
        });
        ret.push({
          label: "GPV",
          text: commentsGPV !== null ? commentsGPV : "",
          rating: this.checkAndChangeScore(scoreGPV),
          isAccess: isGPVEditing
        });
      }
      return ret;
    },
    getAttachments(portalItem) {
      let ret = [];
      if (portalItem) {
        ret = portalItem?.worksession_survey_response_attachments;
      }
      return ret;
    },
    async downloadAllImages() {
      let attachments = [];
      if (this.foundPortals.length > 0) {
        this.foundPortals.map(el => {
          attachments = [
            ...attachments,
            ...el?.worksession_survey_response_attachments
          ];
        });
      }
      let portal_photo_name = this.portalItem?.portal_photo_name;
      for (const el of attachments) {
        if (el && el?.worksessionSurveyResponsePhoto) {
          let imageUri = ApiService.getImageOrFileGlobalUri(
            el.worksessionSurveyResponsePhoto
          );
          try {
            const response = await ApiService.get(imageUri, {
              responseType: "arraybuffer"
            });
            download(response, portal_photo_name + ".png");
          } catch (error) {
            console.log("error - ", error);
          }
        }
      }
    },
    async init(
      worksessionSurveyResponseId,
      assignType,
      productId,
      materialId,
      componentOptionId,
      surveyQuestionId,
      posId
    ) {
      this.isLoading = true;
      this.currentSelectedPosId = posId;
      let formbody = {
        worksessionSurveyResponseId,
        assignType,
        productId,
        materialId,
        componentOptionId,
        surveyQuestionId,
        posId
      };
      const { foundPortals, availablePosList } = await ApiService.post(
        "salesforce/portalphotos/dialogdetail",
        formbody
      );
      this.compactPhotos = [];
      this.isCompactView = false;
      this.filteredComparePhoto = null;
      this.filtered_photo_names = [];
      this.foundPortals = foundPortals;
      this.availablePosList = availablePosList;
      this.visibleItems = 0;
      if (this.foundPortals.length > 0) {
        let foundIndex = this.foundPortals.findIndex(
          el => el.id == worksessionSurveyResponseId
        );
        this.visibleItems = foundIndex;
        if (this.visibleItems >= this.foundPortals.length - 2) {
          this.visibleItems = foundIndex - 1;
        }
        console.log(
          "this.visibleItems - ",
          this.visibleItems,
          this.foundPortals.length
        );
        if (this.visibleItems < 0) this.visibleItems = 0;
      }
      console.log("this.visibleItems - ", this.visibleItems);
      this.isLoading = false;
    }
  }
};
</script>

<style>
.portal-card-slider {
  position: relative;
  padding: 0 4rem;
}
.btn-portal-card-slider {
  position: absolute;
  top: 50%;
  cursor: pointer;
}
.portal-card-slider-prev {
  left: 1rem;
}
.portal-card-slider-next {
  right: 1rem;
}
.btn-portal-move-pos {
  cursor: pointer;
}
</style>
